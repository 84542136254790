import React from 'react';
import { Link } from 'react-router-dom';

import routesPath from 'constants/routesPaths';

const LandingPage = () =>
  <div>
    <h1>Bienvenido a Trashumante Muebles y Diseño</h1>
    <Link to={routesPath.contacto}>Contacto</Link>
  </div>;

export default LandingPage;
