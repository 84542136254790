import React from 'react';
import { Link } from 'react-router-dom';

import routesPath from 'constants/routesPaths';

const ContactPage = () =>
  <div>
    <h1>Pagina de contacto</h1>
    <Link to={routesPath.index}>Inicio</Link>
  </div>;

export default ContactPage;
