import routesPaths from 'constants/routesPaths';
import LandingPage from 'features/landing/LandingPage';
import ContactPage from 'features/contact/ContactPage';

const routes = [
  {
    path: routesPaths.index,
    component: LandingPage,
    exact: true
  },
  {
    path: routesPaths.contacto,
    component: ContactPage
  }
];

export default routes;
